import { useEffect, useState } from "react";
import {FormattedDate} from "react-intl";


  const CurrentTimeDisplay = ({ year, month, weekday, day, hour, minute }: Intl.DateTimeFormatOptions) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setTime(new Date()), 60000);
    return () => clearInterval(timer);
  }, []);

  return (
    <FormattedDate
      value={time}
      year={year}
      month={month}
      weekday={weekday}
      day={day}
      hour={hour}
      minute={minute}
    />
  );
};

export default CurrentTimeDisplay;
