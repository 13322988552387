import {useContext} from "react";
import {FormattedMessage} from "react-intl";
import classNames from "classnames";

import Tag from "./tag";
import AppContext from "../app-context";
import tireImage from "../assets/Images/tire.svg";
import {CONFIG, SeasonEnum, TIRE_STATE} from "../constants";
import {Tire as TireModal} from "../models/tire";
import {tireDescription} from "../utils/tires";

type TireProps = {
  tire: Partial<TireModal>;
  position?: string;
}

const Tire = ({ tire, position = "right"}: TireProps) => {
  const { locale } = useContext(AppContext);
  const { translation, color } = tire.recommendation ? CONFIG[tire.recommendation] : CONFIG[TIRE_STATE.ERROR];
  const stylePosition = position === "right" ? "right-0 -mr-10" : "left-0 -ml-10";
  const description = tireDescription(tire);
  const { tireBrand, season, pressure } = tire;
  const hasSpecifiedSeason = season && season !== SeasonEnum.UNSPECIFIED;
  const hasTireDetails = [tireBrand, pressure, hasSpecifiedSeason, description].some(Boolean);

  return (
    <div
      className={classNames("flex flex-col gap-4 text-dark flex-1 items-center justify-center w-full bg-lightGrey rounded-3xl p-12 relative", {
        "flex-row-reverse": tire.position?.index === 2,
      })}
    >
      {tire.tireLabel?.[locale] && (
          <Tag
              content={<FormattedMessage {...translation} />}
              color={color}
          />
      )}
      {tire.depth && <p className="text-2vw">{`${tire.depth.toFixed(1)}mm`}</p>}
      {hasTireDetails && (
          <ul className="text-1vw text-grey70 font-normal flex flex-col gap-1">
            {tireBrand?.name && <li>{tireBrand.name}</li>}
            {description && <li>{description}</li>}
            {hasSpecifiedSeason && <li><FormattedMessage id={`tire.tireType.${tire.season}`} defaultMessage={tire.season} /></li>}
            {pressure && <li>{<FormattedMessage id="tire.pressure" defaultMessage="Pression: {{pressure}}" values={{ pressure }}  />}</li>}
          </ul>)
      }
      <img src={tireImage} className={classNames('w-20 absolute',stylePosition)} alt="Tire" />
    </div>
  );
};

export default Tire;
