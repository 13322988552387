import React from "react";
import classNames from "classnames";

type LabelProps = {
  title?: React.ReactNode | string;
  content?: React.ReactNode;
  reverse?: boolean;
  customerInfos?: boolean;
  damages?: boolean;
}

const Label = ({damages, customerInfos, title, content }: LabelProps) => {
    return (
        <div className={classNames(
          "flex items-center text-center w-full flex-col",
          {'!flex-row': damages})}
          >
          {title && <p className={classNames(
            'text-1.25vw',
            {'flex-1 text-left': damages},
            {'text-white/60 uppercase font-medium': customerInfos}
            )}
            >{title}</p>
          }
          {content !== undefined && (
            <p className={classNames(
              'text-2vw font-bold', 
              {'w-20 h-20 rounded-full flex justify-center items-center bg-white text-dark ring-8 ring-white/30': damages},
              {'text-white': customerInfos}
            )}
            >{content}</p>
        )}
      </div>
  );
}
export default Label;
