import {LanguageEnum} from "../models/enums/language.enum";
import {StatusEnum} from "../models/enums/status.enum";

export const DEFAULT_CONTEXT = {
    context: {},
    locale: LanguageEnum.EN,
    report: {},
    messages: {},
    pairingInformation: {},
    status: StatusEnum.UNPAIRED,
    isTranslationsLoading: false,
    setContext: () => { },
    setLocale: () => { },
    setReport: () => { },
    setMessages: () => { },
    setIsTranslationsLoading: () => { },
    setPairingInformation: () => { },
    setStatus: () => { }
};