import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './styles/index.css';
import './init-sentry';
import App from './app';

const environment = import.meta.env.VITE_ENV_NAME;
const isProduction = environment  === 'production';

createRoot(document.getElementById('root')!).render(
    <StrictMode>
        <App/>
        {!isProduction && <div className="env-banner">{environment}</div>}
    </StrictMode>,
);