import React from 'react';

import login from "../services/login";
import {StatusEnum} from "../models/enums/status.enum";

import type {Context} from "../models/context";
import type {PairingInformations} from "../models/pairing-informations";

type CodeDisplayProps = PairingInformations & Pick<Context, 'setPairingInformation'>;
const CodeDisplay: React.FC<CodeDisplayProps> = ({ code, codeExpiration, setPairingInformation }) => {
    const intervalIdRef = React.useRef<NodeJS.Timeout>();

    const [isExpiringSoon, setIsExpiringSoon] = React.useState(false);
    const [timeLeftPercentage, setTimeLeftPercentage] = React.useState(100);

    const handleRefreshCode = React.useCallback(async () => {
        const pairingInformation = await login();
        if (pairingInformation.status === StatusEnum.PAIRED) {
            window.location.reload();
        } else {
            setPairingInformation(pairingInformation);
        }
    }, [setPairingInformation]);

    const getTimeRemaining = React.useCallback(() => {
        const expirationDate = new Date(codeExpiration).getTime();
        return expirationDate - Date.now();
    }, [codeExpiration]);

    const totalTimeRemaining = React.useMemo(getTimeRemaining, [getTimeRemaining])

    const updateProgress = React.useCallback(() => {
        const timeRemaining = getTimeRemaining();
        const percentage = Math.max((timeRemaining / totalTimeRemaining) * 100, 0);
        setTimeLeftPercentage(percentage);
        setIsExpiringSoon(percentage <= 5);

        if (percentage === 0) {
            clearInterval(intervalIdRef.current)
            handleRefreshCode();
        }
    }, [totalTimeRemaining, handleRefreshCode, getTimeRemaining]);

    React.useEffect(() => {
        intervalIdRef.current = setInterval(updateProgress, 1000);
        return () => clearInterval(intervalIdRef.current);
    }, [codeExpiration, updateProgress])

    return (
        <div className="w-full flex flex-col gap-3">
            <div className={`flex justify-center gap-2 w-full`}>
                {code?.split('').map((char, index) => (
                    <div key={index} className={`flex-1 rounded-lg flex items-center justify-center bg-black`}>
                        <div className={`text-center text-1.5vw text-white font-bold`}>
                            {char}
                        </div>
                    </div>
                ))}
            </div>
            <div className="h-1 w-full bg-neutral-200 dark:bg-neutral-600">
                <div className={`h-1 ${isExpiringSoon ? 'bg-red' : 'bg-green'}`} style={{width: `${timeLeftPercentage}%`}}></div>
            </div>
        </div>
    );
};

export default CodeDisplay;
