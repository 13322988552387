
import {useContext, useLayoutEffect, useState} from 'react';
import {WELCOME} from "../constants";
import classNames from "classnames";
import CurrentTimeDisplay from "./current-time-display";
import Layout from "./layout";
import AppContext from "../app-context";
import CodeDisplay from "./code-display";
import useTranslations from "../hooks/use-translations";
import useWebSocket from "../hooks/use-web-socket";
import {FormattedMessage} from "react-intl";

const Login = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { context, pairingInformation, setPairingInformation } = useContext(AppContext);
    useWebSocket();
    useTranslations(context.customerDefaultLanguage);

    useLayoutEffect(() => {
        let interval = setInterval(() => {
            setActiveIndex((activeIndex) => (activeIndex + 1) % WELCOME.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    const instructions = [
        { id: "login.instruction1", defaultMessage: "Go to Fleetback management" },
        { id: "login.instruction2", defaultMessage: "Select the camera to pair" },
        { id: "login.instruction3", defaultMessage: "Enter the following code" },
    ];

    return (
        <Layout className="justify-center">
          <div className="flex flex-col h-screen overflow-hidden z-10">
            <div className="flex h-screen gap-16 px-20 py-24 items-center">
                <div className="flex flex-col gap-12 items-start">
                    <div className="flex-col justify-start items-start flex">
                        {WELCOME.map((welcome, index) => (
                            <p
                                key={index}
                                className={classNames(
                                    "text-8vw leading-9vw transition-opacity duration-2000",
                                    index === activeIndex ? "opacity-100" : "opacity-40"
                                )}
                            >
                                {welcome}
                            </p>
                        ))}
                    </div>
                    <div className="px-9 py-6 gap-3 rounded-large text-1.5vw mt-5 bg-mainBlack ring-8 ring-dark">
                        <CurrentTimeDisplay
                            year="numeric"
                            month="long"
                            weekday="long"
                            day="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                  </div>
                </div>
                <div className="p-6 rounded-medium bg-white backdrop-blur-sm flex flex-col w-full justify-center items-center gap-4">
                    <div className="w-full flex flex-col items-center justify-between p-4 gap-3">
                        <div className="text-center text-dark text-2vw font-bold mb-2">
                            <FormattedMessage id="login.signIn" defaultMessage="Sign in with your computer" />
                        </div>
                        {instructions.map((instruction, index) => (
                            <div key={instruction.id} className="w-full text-dark text-1.25vw flex gap-2">
                                <div className="w-5">{index + 1}.</div>
                                <div className="truncate font-normal"><FormattedMessage {...instruction} /></div>
                            </div>
                        ))}
                        {pairingInformation.code && pairingInformation.codeExpiration && (
                            <CodeDisplay code={pairingInformation.code} codeExpiration={pairingInformation.codeExpiration} setPairingInformation={setPairingInformation} />
                        )}
                        <div className="text-center text-dark text-1.25vw mt-4 font-normal">
                            <FormattedMessage id="login.connectedMessage" defaultMessage="When connected, this page automatically updates." />
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </Layout>
    );
};

export default Login;
