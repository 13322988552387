import {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";

import AppContext from "../app-context";
import useWebSocket from "../hooks/use-web-socket";

const ProtectedRoute = () => {
    useWebSocket();
    const { context } = useContext(AppContext);

    // Check if context state is empty on refresh
    return Object.keys(context).length !== 0 ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
