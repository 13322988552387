import { useContext } from "react";

import AppContext from "../app-context";
import getLogo from "../utils/brand";
import CurrentTimeDisplay from "./current-time-display";

const Navbar = () => {
  const { context } = useContext(AppContext);
  const logo = getLogo(context);

  return (
    <nav className="flex items-center font-bold text-white text-1.25vw tv:text-5xl px-20 py-12">
      <div className="flex flex-1 items-center gap-4">
        <img className="aspect-square  h-24" src={logo} alt="Logo" />
        <p>{context.dealerName}</p>
      </div>
      <div className="flex flex-1 justify-end">
        <div className="px-9 py-6 gap-3 rounded-large text-1.5vw mt-5 bg-mainBlack ring-8 ring-dark">
          <CurrentTimeDisplay
            year="numeric"
            month="long"
            weekday="long"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
