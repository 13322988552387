import React from "react";
import classNames from "classnames";

export const backgroundsMap = {
  red: "bg-red",
  green: "bg-green",
  orange: "bg-orange",
  dark: "bg-dark",
};

type LabelProps = {
  content?: React.ReactNode;
  color?: string;
}

type ColorKey = keyof typeof backgroundsMap;

const Label = ({  content, color }: LabelProps) => {
    const backgroundClass = backgroundsMap[color as ColorKey];
    return (
        <div className={classNames("inline-flex items-center text-center px-3 py-1 rounded-xl", backgroundClass)}>
          {content !== undefined && <p className={classNames('text-1.5vw font-medium text-white')}>{content}</p>}
      </div>
  );
}
export default Label;
