import { FormattedMessage } from "react-intl";

import Tire from "./tire";
import car from "../assets/Images/car.png";
import {Tire as TireModel} from "../models/tire";

type CarProps = {
    tires: Partial<TireModel>[];
}

const Car = ({ tires }: CarProps) => {
  return (
    <>
      <div className="flex flex-col justify-around w-1/3 gap-6">
        <Tire tire={tires[0]} />
        <Tire tire={tires[2]} />
      </div>
      <div className="flex flex-col items-center justify-between w-1/3">
        <p className="opacity-20 text-2vw tv:text-2vw font-bold text-center uppercase text-darkGrey mt-10">
          <FormattedMessage id="front" defaultMessage="Front" />
        </p>
        <img className="w-2/3 tv:w-4/5" src={car} alt="Car" />
        <p className="opacity-20 text-2vw tv:text-2vw font-bold text-center uppercase text-darkGrey mb-10">
          <FormattedMessage id="rear" defaultMessage="Rear" />
        </p>
      </div>
      <div className="flex flex-col justify-around w-1/3 gap-6">
        <Tire tire={tires[1]} position="left" />
        <Tire tire={tires[3]} position="left" />
      </div>
    </>
  );
};

export default Car;
