import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import * as Sentry from '@sentry/react';

import Layout from "../layout";
import getLogo from "../../utils/brand";
import AppContext from "../../app-context";
import { HOME } from "../../constants/routes";
import {useNavigate, useRouteError} from "react-router-dom";
import {UNASSIGNED_ERROR} from "../../constants/error";

interface RouteError {
  status?: number;
  statusText?: string;
  data?: string;
}

const ErrorNotFound = () => {
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");
  const { context } = useContext(AppContext);
  const logo = getLogo(context);
  const error = useRouteError() as RouteError;

  useEffect(() => {
    if (jwt) {
      const timeoutId = setTimeout(() => {
        navigate(HOME);
      }, 60000); // Time will increase, 1 minute for debug
      return () => clearTimeout(timeoutId);
    }
  }, [navigate, jwt]);

  const isHardwareUnassigned = error.data?.includes(UNASSIGNED_ERROR);

  if (!isHardwareUnassigned) {
    Sentry.captureException(error);
  }

  return (
    <Layout className="justify-center">
      <div className="flex flex-col text-center text-1.5vw gap-12">
        <img src={logo} alt="Logo" />
        {isHardwareUnassigned ? <p>Hardware is not assigned to any dealer</p> : (
            <>
              <FormattedMessage id="error" defaultMessage="Error" tagName="p" />
              <FormattedMessage
                  id="error404.notFound"
                  defaultMessage="Page not found"
                  tagName="p"
              />
            </>
        )}
      </div>
    </Layout>
  );
};
export default ErrorNotFound;
