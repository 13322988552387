import { useContext, useLayoutEffect, useState } from "react";
import classNames from "classnames";

import Layout from "./layout";
import Loader from "./loader";
import AppContext from "../app-context";
import { WELCOME } from "../constants";
import CurrentTimeDisplay from "./current-time-display";
import useTranslations from "../hooks/use-translations";

const Home = () => {
  const { context, isTranslationsLoading } = useContext(AppContext);
  const [activeIndex, setActiveIndex] = useState(0);
  useTranslations(context?.customerDefaultLanguage);

  useLayoutEffect(() => {
    let interval = setInterval(() => {
      setActiveIndex((activeIndex) => (activeIndex + 1) % WELCOME.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return isTranslationsLoading ? (
    <Loader />
  ) : (
    <Layout>
      <div className="flex flex-col px-32 gap-12 w-full items-center">
        <div className="flex items-center">
          <p className="flex-1 leading-normal text-2vw">
            {context.dealerName}
          </p>
        </div>
        <div className="flex-col h-5/6 items-start">
          {WELCOME.map((welcome, index) => (
            <p
              key={index}
              className={classNames(
                "text-8vw leading-9vw transition-opacity duration-2000",
                index === activeIndex ? "opacity-100" : "opacity-40"
              )}
            >
              {welcome}
            </p>
          ))}
        </div>
        <div className="px-9 py-6 gap-3 rounded-large text-1.5vw mt-5 bg-mainBlack ring-8 ring-dark">
          <CurrentTimeDisplay
            year="numeric"
            month="long"
            weekday="long"
            day="numeric"
            hour="2-digit"
            minute="2-digit"
          />
        </div>
      </div>
    </Layout>
  );
};

export default Home;